import { actionTypes } from '../actions/actionTypes/timesheetActionType';

let initialState = {
  personalTimeSheet: [],
  postTimeSheet: '',
  stopSubmit: false,
  teamTimeSheet: [],
  showTimesheetLoader: false,
  managerProjects: [],
  ProjectList: [],
  PageIndexCount: 0,
  IsAdvancedTimeSheet: false,
  IsTeamTimeSheetAdvanced: false,
  ShowAdvancedTSReport: false,
  downloadTSReportUserLoader: false,
  TSReportData: {},
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function timeSheet(state = initialState, action) {
  switch (action.type) {
    case actionTypes.NO_ACTION:
      return {
        ...state,
        showTimesheetLoader: false,
      };

    case actionTypes.GET_TIMESHEET_SUCCESS:
      return {
        ...state,
        personalTimeSheet: action.cases,
        IsAdvancedTimeSheet: action.cases.IsAdvancedTimeSheet === 'true' ? true : false,
        ShowAdvancedTSReport: action.cases.ShowAdvancedTSReport === 'true' ? true : false,
        showTimesheetLoader: false,
      };
    case actionTypes.GET_TIMESHEET_FAILURE:
      return {
        ...state,
        personalTimeSheet: [
          {
            id: Math.random(),
            ProjectID: '',
            UserComments: '',
            Days: {
              Sunday: 0,
              Monday: 0,
              Tuesday: 0,
              Wednesday: 0,
              Thursday: 0,
              Friday: 0,
              Saturday: 0,
            },
            Notes: {
              Sunday: '',
              Monday: '',
              Tuesday: '',
              Wednesday: '',
              Thursday: '',
              Friday: '',
              Saturday: '',
            },
            Total: 0,
          },
        ],
        showTimesheetLoader: false,
      };

    case actionTypes.POST_TIMESHEET_SUCCESS:
      return {
        ...state,
        personalTimeSheet: action.cases,
        showTimesheetLoader: false,
        stopSubmit: true,
      };
    case actionTypes.POST_TIMESHEET_FAILURE:
      return {
        ...state,
        stopSubmit: true,
      };
    case actionTypes.SHOW_LOADER_TIMESHEET:
      return {
        ...state,
        showTimesheetLoader: true,
      };
    case actionTypes.GET_TEAM_TIMESHEET_SUCCESS:
      return {
        ...state,
        teamTimeSheet: action.cases[0] ? action.cases[0].Day : [],
        managerProjects:
          action.cases[0] && action.cases[0].ProjectList
            ? action.cases[0].ProjectList
            : [],
        PageIndexCount: action.cases[0] && action.cases[0]?.PageIndexCount,
        IsTeamTimeSheetAdvanced:
          action.cases[0] && action.cases[0]?.IsAdvancedTimeSheet === 'true' ? true : false,

        ProjectList: action.cases[0] && action.cases[0].ProjectList,
        teamTimeSheetItemsLoader: false,
        getOldRecordsCount: action.cases[0] ? action.cases[0].PendingApprovals : [],
        holidayorLeaveList: action.cases[0] ? action.cases[0].pyList : '',
      };
    case actionTypes.GET_TEAM_TIMESHEET_FAILURE:
      return {
        ...state,
        teamTimeSheet: [],
        teamTimeSheetItemsLoader: false,
      };
    case actionTypes.SHOW_LOADER_TIMESHEET_SUCCESS:
      return {
        ...state,
        teamTimeSheetLoader: true,
      };
    case actionTypes.POST_TIMESHEET_STATUS_SUCCESS:
      return {
        ...state,
        teamTimeSheet: action.teamitems ? action.teamitems.Day : [],
        teamTimeSheetLoader: false,
        holidayorLeaveList: action.teamitems ? action.teamitems.pyList : '',
        getOldRecordsCount: action.teamitems ? action.teamitems.PendingApprovals : [],
        PageIndexCount: action.teamitems?.PageIndexCount || 0,
      };
    case actionTypes.POST_TIMESHEET_STATUS_FAILURE:
      return {
        ...state,
        teamTimeSheetLoader: false,
      };
    case actionTypes.SHOW_TEAM_TIMESHEETS_LOADER:
      return {
        ...state,
        teamTimeSheetItemsLoader: true,
      };

    case actionTypes.TIMESHEET_BULK_ACTION_BY_PROJECT_SUCESS:
      return {
        ...state,
        teamTimeSheet: action.teamitems ? action.teamitems.Day : [],
        managerProjects:
          action.teamitems && action.teamitems.ProjectList
            ? action.teamitems.ProjectList
            : [],
        PageIndexCount: action.teamitems && action.teamitems?.PageIndexCount,
        ProjectList: action.teamitems && action.teamitems.ProjectList,
        teamTimeSheetLoader: false,
        getOldRecordsCount: action.teamitems ? action.teamitems.PendingApprovals : [],
        holidayorLeaveList: action.teamitems ? action.teamitems.pyList : '',
      };
    case actionTypes.TIMESHEET_BULK_ACTION_BY_PROJECT_FAILURE:
      return {
        ...state,
        teamTimeSheetLoader: false,
      };

    case actionTypes.DOWNLOAD_TIMESHEET_REPORT_USER_SUCCESS:
      return {
        ...state,
        downloadTSReportUserLoader: false,
        TSReportData: action?.cases || {},
      };

    case actionTypes.DOWNLOAD_TIMESHEET_REPORT_USER_FAILURE:
      return {
        ...state,
        downloadTSReportUserLoader: false,
      };

    case actionTypes.DOWNLOAD_TIMESHEET_REPORT_USER_LOADER:
      return {
        ...state,
        downloadTSReportUserLoader: true,
      };

    default:
      return state;
  }
}
