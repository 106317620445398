import { actionTypes } from '../actions/actionTypes/projectManagementActionTypes';

let initialState = {
  MyDraftProjectInfo: {},
  MyProjectCaseInfo: {},
  MyResourceNeedCaseInfo: {},
  GetProjectInfoLoader: false,
  NewProjectInfo: {},
  NewProjectInfoLoader: false,
  SaveOrCreateProjectLoader: false,
  openPMCaseLoader: false,
  PMCaseData: {},
  isProjectSavedSuccessfully: false,
  isCaseWithdrawnSuccessfully: false,
  GoToHome: false,
  pagination: 0,
  GetMyProjectCasesLoader: false,
  ProjectCases: [],
  ApproveOrRejectPMcaseLoader: false,
  CreateResourceInfoLoader: false,
  Roleslist: [],
  SkillsList: [],
  CreateResourceNeedCaseLoader: false,
  Commentslist: [],
  withdrawCaseLoader: false,
  ResourceNeedInfo: {},
  WithdrawReason: '',
  HoldAndReserveLoader: false,
  holdedResources: [],
  reservedResources: [],
  allocatedResources: [],
  tabIndex: 0,
  ResourceNeedCaseInfo: {},
  SwapResourceCaseInfo: {},
  RNCaseLoader: false,

  SwapCaseDetails: {},
  SwapCaseDetailsLoader: false,

  SearchEmpPMLoader: false,
  ActiveEmployeeList: [],
  ProjectMaintenanceOptionMenu: [],
  closeProjectLoader: false,
  isClickedOnApply: false,
  ApplyEndDateLoader: false,

  submitProjectUpdateLoader: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function ProjectManagement(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECTMANAGEMENT_SUCCESS:
      return {
        ...state,
        GetProjectInfoLoader: false,
        MyDraftProjectInfo: action.data?.MyDraftProjectInfo || {},
        MyProjectCaseInfo: action.data?.MyProjectCaseInfo || {},
        MyResourceNeedCaseInfo: action.data?.MyResourceNeedCaseInfo || {},
        GoToHome: false,
      };
    case actionTypes.GET_PROJECTMANAGEMENT_FAILURE:
      return {
        ...state,
        GetProjectInfoLoader: false,
        GoToHome: false,
      };
    case actionTypes.GET_PROJECTMANAGEMENT_LOADER:
      return {
        ...state,
        GetProjectInfoLoader: true,
      };

    case actionTypes.CREATE_PROJECTMANAGEMENT_INFO_SUCCESS:
      return {
        ...state,
        NewProjectInfoLoader: false,
        NewProjectInfo: action.data.ProjectInfo || {},
      };
    case actionTypes.CREATE_PROJECTMANAGEMENT_INFO_FAILURE:
      return {
        ...state,
        NewProjectInfoLoader: false,
      };
    case actionTypes.CREATE_PROJECTMANAGEMENT_INFO_LOADER:
      return {
        ...state,
        NewProjectInfoLoader: true,
      };

    case actionTypes.SAVEORCREATE_PROJECT_SUCCESS:
      return {
        ...state,
        SaveOrCreateProjectLoader: false,
        // NewProjectInfo: action.data.ProjectInfo || {},
        isProjectSavedSuccessfully: action?.data?.ProjectInfo?.pyErrorPage?.pyRawMessage
          ? false
          : true,
      };
    case actionTypes.SAVEORCREATE_PROJECT_FAILURE:
      return {
        ...state,
        SaveOrCreateProjectLoader: false,
        isProjectSavedSuccessfully: false,
      };
    case actionTypes.SAVEORCREATE_PROJECT_LOADER:
      return {
        ...state,
        SaveOrCreateProjectLoader: true,
      };

    case actionTypes.OPEN_PM_CASE_SUCCESS:
      return {
        ...state,
        openPMCaseLoader: false,
        PMCaseData: action?.data?.ProjectInfo || {},
        Commentslist: action?.data?.Commentslist || [],
        WithdrawReason: action?.data?.WithdrawReason || '',
        holdedResources: action?.data?.ProjectInfo?.HoldedEmployeeList || [],
        reservedResources: action?.data?.ProjectInfo?.ReserveEmployeeList || [],
        allocatedResources: action?.data?.ProjectInfo?.AllocatedEmployeeList || [],
        ResourceNeedCaseInfo: action?.data?.ResourceNeedCaseInfo || {},
        SwapResourceCaseInfo: action?.data?.SwapResourceCaseInfo || {},
        ProjectMaintenanceOptionMenu: action?.data?.ProjectMaintenanceOptionMenu || [],
        RNCaseLoader: false,
      };
    case actionTypes.OPEN_PM_CASE_FAILURE:
      return {
        ...state,
        openPMCaseLoader: false,
        RNCaseLoader: false,
      };
    case actionTypes.OPEN_PM_CASE_LOADER:
      return {
        ...state,
        openPMCaseLoader: true,
      };
    case actionTypes.RESET_PM_DATA_NAV:
      return {
        ...state,
        isProjectSavedSuccessfully: false,
        Commentslist: [],
        PMCaseData: {},
        isCaseWithdrawnSuccessfully: false,
        GoToHome: false,
        ResourceNeedInfo: {},
        WithdrawReason: '',
        holdedResources: [],
        reservedResources: [],
        isClickedOnApply: false,
      };

    case actionTypes.CLEAR_EMP_LIST:
      return {
        ...state,
        ActiveEmployeeList: [],
        isClickedOnApply: false,
      };

    case actionTypes.GET_MY_PROJECTS_SUCCESS:
      return {
        ...state,
        GetMyProjectCasesLoader: false,
        pagination: parseInt(action?.data?.PageIndexCount),
        ProjectCases:
          action?.data?.ProjectCaseList || action?.data?.ManageResourceCaseList || [],
      };
    case actionTypes.GET_MY_PROJECTS_FAILURE:
      return {
        ...state,
        GetMyProjectCasesLoader: false,
      };
    case actionTypes.GET_MY_PROJECTS_LOADER:
      return {
        ...state,
        GetMyProjectCasesLoader: true,
      };

    case actionTypes.UPDATE_PM_DATA:
      return {
        ...state,
        PMCaseData: action.data.row || {},
        Commentslist: action.data.Commentslist || [],
      };

    case actionTypes.APPROVE_OR_REJECT_PM_CASE_SUCCESS:
      return {
        ...state,
        ApproveOrRejectPMcaseLoader: false,
      };
    case actionTypes.APPROVE_OR_REJECT_PM_CASE_FAILURE:
      return {
        ...state,
        ApproveOrRejectPMcaseLoader: false,
      };

    case actionTypes.APPROVE_OR_REJECT_PM_CASE_LOADER:
      return {
        ...state,
        ApproveOrRejectPMcaseLoader: true,
      };

    case actionTypes.NEED_RESOURCE_INFO_SUCCESS:
      return {
        ...state,
        CreateResourceInfoLoader: false,
        SkillsList: action.data.SkillsList || [],
        Roleslist: action.data.Roleslist || [],
      };
    case actionTypes.NEED_RESOURCE_INFO_FAILURE:
      return {
        ...state,
        CreateResourceInfoLoader: false,
      };

    case actionTypes.NEED_RESOURCE_INFO_LOADER:
      return {
        ...state,
        CreateResourceInfoLoader: true,
      };

    case actionTypes.CREATE_NEED_RESOURCE_INFO_SUCCESS:
      return {
        ...state,
        GoToHome:
          action?.data?.actionType === 'CreateandClose' ||
          action?.data?.actionType === 'SaveResourceDetails',
        CreateResourceNeedCaseLoader: false,
        ResourceNeedInfo: action?.data,
        PMCaseData:
          action?.data?.actionType === 'Search Resource' ||
          action?.data?.actionType === 'SearchForOtherRoles'
            ? {
                ...state.PMCaseData,
                actionType: action?.data?.ResourceNeedInfo?.actionType || '',
                ReserveDisabledReason:
                  action?.data?.ResourceNeedInfo?.ReserveDisabledReason || '',
                EnableReserve: action?.data?.ResourceNeedInfo?.EnableReserve || '',
              }
            : action?.data?.ResourceNeedInfo || {},
      };
    case actionTypes.CREATE_NEED_RESOURCE_INFO_FAILURE:
      return {
        ...state,
        GoToHome: false,
        CreateResourceNeedCaseLoader: false,
      };

    case actionTypes.CREATE_NEED_RESOURCE_INFO_LOADER:
      return {
        ...state,
        CreateResourceNeedCaseLoader: true,
      };

    case actionTypes.WITHDRAW_PM_CASE_SUCCESS:
      return {
        ...state,
        withdrawCaseLoader: false,
        isCaseWithdrawnSuccessfully: true,
      };
    case actionTypes.WITHDRAW_PM_CASE_FAILURE:
      return {
        ...state,
        withdrawCaseLoader: false,
        isCaseWithdrawnSuccessfully: false,
      };

    case actionTypes.WITHDRAW_PM_CASE_LOADER:
      return {
        ...state,
        withdrawCaseLoader: true,
      };
    case actionTypes.CREATE_NEED_RESOURCE_INFO_LOADER:
      return {
        ...state,
        CreateResourceNeedCaseLoader: true,
      };

    case actionTypes.HOLD_OR_RESERVE_SUCCESS:
      return {
        ...state,
        HoldAndReserveLoader: false,
        holdedResources: action?.data?.HoldedEmployeeList || [],
        reservedResources: action?.data?.ReserveEmployeeList || [],
        allocatedResources: action?.data?.AllocatedEmployeeList || [],
      };
    case actionTypes.HOLD_OR_RESERVE_FAILURE:
      return {
        ...state,
        HoldAndReserveLoader: false,
      };

    case actionTypes.HOLD_OR_RESERVE_LOADER:
      return {
        ...state,
        HoldAndReserveLoader: true,
      };

    case actionTypes.SET_TAB_INDEX_PM:
      return {
        ...state,
        tabIndex: action?.index,
      };

    case actionTypes.SHOW_LOADER_RN_CASE:
      return {
        ...state,
        RNCaseLoader: true,
      };
    case actionTypes.SHOW_RN_CASE_SUCCESS:
      return {
        ...state,
        openPMCaseLoader: false,
        holdedResources: action?.data?.HoldedEmployeeList || [],
        reservedResources: action?.data?.ReserveEmployeeList || [],
        allocatedResources: action?.data?.AllocatedEmployeeList || [],
        RNCaseLoader: false,
      };
    case actionTypes.SHOW_RN_CASE_FAILURE:
      return {
        ...state,
        RNCaseLoader: false,
      };

    case actionTypes.OPEN_SWAP_CASE_TEAMS_SUCCESS:
      return {
        ...state,
        SwapCaseDetailsLoader: false,
        SwapCaseDetails: action.data,
      };
    case actionTypes.OPEN_SWAP_CASE_TEAMS_FAILURE:
      return {
        ...state,
        SwapCaseDetailsLoader: false,
      };
    case actionTypes.OPEN_SWAP_CASE_TEAMS_LOADER:
      return {
        ...state,
        SwapCaseDetailsLoader: true,
      };

    case actionTypes.SEARCH_EMP_PM_SUCCESS:
      return {
        ...state,
        SearchEmpPMLoader: false,
        ActiveEmployeeList: action?.data?.ActiveEmployeeList || [],
      };
    case actionTypes.SEARCH_EMP_PM_FAILURE:
      return {
        ...state,
        SearchEmpPMLoader: false,
      };
    case actionTypes.SEARCH_EMP_PM_LOADER:
      return {
        ...state,
        SearchEmpPMLoader: true,
      };

    case actionTypes.CLOSE_PROJECT_PM_LOADER:
      return {
        ...state,
        closeProjectLoader: true,
      };
    case actionTypes.CLOSE_PROJECT_PM_SUCCESS:
      return {
        ...state,
        closeProjectLoader: false,
        GoToHome: action?.data?.pyNote ? true : false,
      };
    case actionTypes.CLOSE_PROJECT_PM_FAILURE:
      return {
        ...state,
        closeProjectLoader: false,
      };

    case actionTypes.CLICK_APPLY_LOADER:
      return {
        ...state,
        ApplyEndDateLoader: true,
      };
    case actionTypes.CLICK_APPLY_SUCCESS:
      return {
        ...state,
        ApplyEndDateLoader: false,
        isClickedOnApply: true,
        PMCaseData:
          {
            ...state.PMCaseData,
            CurrentAllocation: action?.data?.ProjectInfo?.CurrentAllocation || [],
            UpdatedEndDate: action?.data?.ProjectInfo?.UpdatedEndDate || null,
          } || {},
      };
    case actionTypes.CLICK_APPLY_FAILURE:
      return {
        ...state,
        ApplyEndDateLoader: false,
      };

    case actionTypes.SUBMIT_PROJECT_UPDATE_LOADER:
      return {
        ...state,
        submitProjectUpdateLoader: true,
      };
    case actionTypes.SUBMIT_PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        submitProjectUpdateLoader: false,
        PMCaseData: action?.data?.ProjectInfo || {},
        GoToHome: action?.data?.pyNote ? true : false,
      };
    case actionTypes.SUBMIT_PROJECT_UPDATE_FAILURE:
      return {
        ...state,
        submitProjectUpdateLoader: false,
      };

    default:
      return state;
  }
}
