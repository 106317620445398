import { actionTypes } from '../actions/actionTypes/ProjectMaintnanceActionTypes';

let initialState = {
  ClosedProjectCaseList: [],
  ProjectMaintenanceGetCallData: {},
  formBasicData: {},
  InflightProjectCaseList: [],
  SavedProjectCaseList: [],
  saveOrSubmitData: {},
  ProjectCaseList: [],
  getProjectMaintnanceLoader: false,
  projectsList: [],
  searchProjectsLoader: false,
  ProjectInfo: {},
  submitSuccessNote: '',
  saveSuccessNote: '',
  editData: {},
  labelForSavedCases: '',
  loaderForEditProject: false,
  ProjectMaintenanceOptionMenu: [],
  openProjecttLoader: false,
  pagination: 0,
  pagesSates: {
    EnableProjectDetailsPage: false,
    EnableCreateOrEditProject: 0,
    EnableCloseProject: 0,
  },
  formLists: {},
  formBasicDataLoader: false,
  projectData: {},
  ResourceNeedCaseInfo: {},
  resourceNeedCaseLoader: false,
  SwapResourceCaseInfo: {},
  searchEmpProjectsLoader: false,
  SwappeProjectsList: [],
  swapResourceLoader: false,
  SwapperDetails: {},
  swapCaseDetailsLoader: false,
  SwapCaseDetails: {},
  ActiveEmployeeList: [],
  closePopup: false,
  WarningMessage: '',
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function projectMaintenanceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        ClosedProjectCaseList: action.cases?.ClosedProjectCaseList || [],
        ProjectMaintenanceGetCallData: action?.cases || {},
        InflightProjectCaseList: action.cases?.InflightProjectCaseList || [],
        formBasicData: action.cases?.ProjectInfo || {},
        pagination: action.cases?.PageIndexCount,
        saveOrSubmitData: {},
        submitSuccessNote: '',
        saveSuccessNote: '',
        ProjectCaseList: action.cases.ProjectCaseList || [],
        SavedProjectCaseList: action.cases.SavedProjectCaseList || [],
        getProjectMaintnanceLoader: false,
        WarningMessage: '',
        pagesSates: {
          ...state.pagesSates,
          EnableProjectDetailsPage: false,
        },
      };

    case actionTypes.GET_PROJECT_MAINTENANCE_FIALURE:
      return {
        ...state,
        formBasicData: {},
        ClosedProjectCaseList: [],
        InflightProjectCaseList: [],
        saveOrSubmitData: {},
        ProjectCaseList: [],
        getProjectMaintnanceLoader: false,
        pagesSates: {
          ...state.pagesSates,
          EnableProjectDetailsPage: false,
        },
      };

    case actionTypes.GET_PROJECT_MAINTENANCE__LOADER:
      return {
        ...state,
        getProjectMaintnanceLoader: true,
        pagesSates: {
          ...state.pagesSates,
          EnableProjectDetailsPage: false,
        },
      };
    case actionTypes.SEARCH_PROJECT_SUCCESS:
      return {
        ...state,
        projectsList: action?.cases?.ProjectMaintenanceSearchList || [],
        searchProjectsLoader: false,
      };

    case actionTypes.SEARCH_PROJECT_FAILURE:
      return {
        ...state,
        projectsList: [],
        searchProjectsLoader: false,
      };

    case actionTypes.SEARCH_PROJECT_LOADER:
      return {
        ...state,
        searchProjectsLoader: true,
      };

    case actionTypes.OPEN_PROJECT_SUCCESS:
      return {
        ...state,
        ProjectInfo: action.cases.ProjectInfo || {},
        ProjectMaintenanceOptionMenu: action.cases.ProjectMaintenanceOptionMenu || [],
        openProjecttLoader: false,
        pagesSates: {
          ...state.pagesSates,
          EnableProjectDetailsPage: true,
        },
        ResourceNeedCaseInfo: action.cases?.ResourceNeedCaseInfo || {},
        SwapResourceCaseInfo: action.cases?.SwapResourceCaseInfo || {},
        distinctListOfResources: action.cases?.distinctListOfResources || [],
      };

    case actionTypes.OPEN_PROJECT_FAILURE:
      return {
        ...state,
        ProjectInfo: {},
        ProjectMaintenanceOptionMenu: [],
        openProjecttLoader: false,
      };

    case actionTypes.OPEN_PROJECT_LOADER:
      return {
        ...state,
        openProjecttLoader: true,
      };
    case actionTypes.SET_DATA_PROJECT_MAINTENANCE:
      return {
        ...state,
        pagesSates: {
          EnableProjectDetailsPage: action.data.EnableProjectDetailsPage,
          EnableCreateOrEditProject: action.data.EnableCreateOrEditProject,
          EnableCloseProject: action.data.EnableCloseProject,
        },
        editData: action.data.editData ? action.data.editData : state.editData || {},
        saveOrSubmitData: action?.data?.saveOrSubmitData || {},
        labelForSavedCases: action?.data?.labelForSavedCases || '',
      };
    case actionTypes.GET_fORM_BASICDATA_SUCCESS:
      return {
        ...state,
        formLists: action.cases.ProjectInfo || {},
        ActiveEmployeeList: action.cases.ActiveEmployeeList || [],
        formBasicDataLoader: false,
      };
    case actionTypes.GET_fORM_BASICDATA_FAILURE:
      return {
        ...state,
        formLists: {},
        formBasicDataLoader: false,
      };
    case actionTypes.GET_fORM_BASICDATA_LOADER:
      return {
        ...state,
        formBasicDataLoader: true,
      };

    case actionTypes.SAVE_SUCCESS:
      return {
        ...state,
        saveOrSubmitData: action?.cases || {},
        saveSuccessNote: action?.cases?.pyNote,
        loaderForSaveProject: false,
        submitSuccessNote: '',
      };

    case actionTypes.SAVE_FAILURE:
      return {
        ...state,
        SavedProjectCaseList: [],
        pagination: 0,
        saveOrSubmitData: {},

        loaderForSaveProject: false,
      };
    case actionTypes.SAVE_LOADER:
      return {
        ...state,
        loaderForSaveProject: true,
      };

    case actionTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        saveOrSubmitData: action.cases || {},
        submitSuccessNote: action?.cases?.pyNote,
        WarningMessage: action?.cases?.ProjectInfo?.WarningMessage || '',
        saveSuccessNote: '',
        loaderForSubmitProject: false,
      };
    case actionTypes.SUBMIT_FAILURE:
      return {
        ...state,
        saveOrSubmitData: {},
        loaderForSubmitProject: false,
        WarningMessage: '',
      };
    case actionTypes.SUBMIT_LOADER:
      return {
        ...state,
        loaderForSubmitProject: true,
      };
    case actionTypes.EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        editData: action?.cases?.ProjectInfo || {},
        labelForSavedCases: action?.cases?.ProcessType,
        projectData: action?.cases,
        loaderForEditProject: false,
      };
    case actionTypes.EDIT_PROJECT_FAILURE:
      return {
        ...state,
        labelForSavedCases: '',
        editData: {},
        loaderForEditProject: false,
      };
    case actionTypes.EDIT_PROJECT_LOADER:
      return {
        ...state,
        loaderForEditProject: true,
      };

    case actionTypes.SET_DATA_CASE_AUTHENTICATION:
      return {
        ...state,
        projectData: {},
      };

    case actionTypes.RESET_SWAP_DATA:
      return {
        ...state,
        SwappeProjectsList: [],
        closePopup: false,
        SwapperDetails: {},
        ActiveEmployeeList: [],
      };

    case actionTypes.RESOURCE_NEED_CASE_SUCCESS:
      return {
        ...state,
        resourceNeedCaseLoader: false,
        holdedResources: action?.data?.HoldedEmployeeList || [],
        reservedResources: action?.data?.ReserveEmployeeList || [],
        allocatedResources: action?.data?.AllocatedEmployeeList || [],
      };
    case actionTypes.RESOURCE_NEED_CASE_FAILURE:
      return {
        ...state,
        resourceNeedCaseLoader: false,
      };
    case actionTypes.RESOURCE_NEED_CASE_LOADER:
      return {
        ...state,
        resourceNeedCaseLoader: true,
      };

    case actionTypes.SEARCH_EMP_PROJECT_SUCCESS:
      return {
        ...state,
        searchEmpProjectsLoader: false,
        SwappeProjectsList: action?.cases?.SwappeProjectsList || [],
        SwapperDetails: action.cases?.SwapperDetails || {},
      };
    case actionTypes.SEARCH_EMP_PROJECT_FAILURE:
      return {
        ...state,
        searchEmpProjectsLoader: false,
      };
    case actionTypes.SEARCH_EMP_PROJECT_LOADER:
      return {
        ...state,
        searchEmpProjectsLoader: true,
      };

    case actionTypes.SUBMIT_SWAP_SUCCESS:
      return {
        ...state,
        swapResourceLoader: false,
        closePopup: true,
      };
    case actionTypes.SUBMIT_SWAP_FAILURE:
      return {
        ...state,
        swapResourceLoader: false,
        closePopup: false,
      };
    case actionTypes.SUBMIT_SWAP_LOADER:
      return {
        ...state,
        swapResourceLoader: true,
      };

    case actionTypes.SWAP_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        swapCaseDetailsLoader: false,
        SwapCaseDetails: action.data,
      };
    case actionTypes.SWAP_CASE_DETAILS_FAILURE:
      return {
        ...state,
        swapCaseDetailsLoader: false,
      };
    case actionTypes.SWAP_CASE_DETAILS_LOADER:
      return {
        ...state,
        swapCaseDetailsLoader: true,
      };

    case actionTypes.CLEAR_WARNING_MSG:
      return {
        ...state,
        WarningMessage: '',
      };

    default:
      return state;
  }
}
