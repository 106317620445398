import { actionTypes } from '../actions/actionTypes/payRollActionType';

let initialState = {
  OrgList: [],
  getPayrollLoader: false,
  changeOrg: false,
  payRollLoader: false,
  payRollData: {},
  formW4Data: [],
  formStateW4Data: [],
  getW4ConfigDetailsLoader: false,
  SubmitW4ConfigDetailsLoader: false,
  CaseInfo: [],
  ResolvedCaseInfo: [],
  compensationDetails: [],
  Is2020W4Form: false,
  DisableGenerateFederalPDF: false,
  DisableGenerateStatePDF: false,
  getPayStubsDataLoader: false,
  PayPeriodDates: [],
  EmpId: '',
  payPeriodYears: [],
  downloadPayStubLoader: false,
  IsA2020W4Form: '',
  EmployeeDetails: [],
  Deduct401kfromBonus: '',
  ActiveEmployees: '',
  processOffcycleLoader: false,
  OffCycleCurrency: '',
  emailW4DataLoader: false,
  FederalDocBase64Code: '',
  FederalDocBefore2020Base64: '',
  StateDocBase64Code: '',
  w4Info: {},
  taxDocsDetails: {},
  getTaxDocsDetailsLoader: false,
  sendTaxDocMailLoader: false,
  SelectedLocation: '',
  Region: '',
  PayrollGetCallData: {},
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function payRoll(state = initialState, action) {
  switch (action.type) {
    case actionTypes.POST_PAYROLL_SUCCESS:
      return {
        ...state,
        payRollData: action.cases,
        OffCycleCurrency: action.cases?.CurrencyCode || '',
        EmployeeDetails: action?.cases?.TotalEmployeeList || [],
        payRollLoader: false,
        getProcessPayRollLoader: false,
      };

    case actionTypes.GET_OPEN_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        payRollData: action.cases,
        EmployeeDetails: action?.cases?.TotalEmployeeList || [],
        Deduct401kfromBonus:
          action.cases?.data?.Deduct401kfromBonus === 'true' ||
          action.cases?.Deduct401kfromBonus === 'true'
            ? true
            : false,
        ActiveEmployees: action.cases?.ActiveEmployees,
        SelectedLocation: action?.cases?.LocationCode || '',
        Region: action?.cases?.Region || '',
        getPayrollLoader: false,
        getProcessPayRollLoader: false,
      };

    case actionTypes.GET_OPEN_CASE_DETAILS_FAILURE:
      return {
        ...state,
        payRollData: {},
        getPayrollLoader: false,
      };

    case actionTypes.POST_PAYROLL_FAILURE:
      return {
        ...state,
        payRollData: {},
        payRollLoader: false,
      };

    case actionTypes.RESET_PAYROLL_DATA:
      return {
        ...state,
        payRollData: {},
        EmployeeDetails: [],
      };

    case actionTypes.SHOW_PAYROLL_LOADER:
      return {
        ...state,
        payRollLoader: true,
      };
    case actionTypes.GET_PAYROLL_SUCCESS:
      return {
        ...state,
        OrgList: action.cases.OrgList,
        CaseInfo: action.cases?.CaseInfo || [],
        ResolvedCaseInfo: action.cases?.ResolvedCaseInfo || [],
        getPayrollLoader: false,
        payRollLoader: false,
        PayrollGetCallData: action.cases || {},
      };

    case actionTypes.GET_PAYROLL_FAILURE:
      return {
        ...state,
        OrgList: [],
        getPayrollLoader: false,
        payRollLoader: false,
      };

    case actionTypes.SHOW_PAYROLL_LOADER_GET:
      return {
        ...state,
        getPayrollLoader: true,
      };

    case actionTypes.CHANGE_ORG:
      return {
        ...state,
        changeOrg: action.flag,
      };

    case actionTypes.GET_W4_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        formW4Data: action.cases.FormW4Data || [],
        formStateW4Data: action.cases.FormStateW4Data || [],
        compensationDetails: action.cases.CompensationDetails || [],
        getW4ConfigDetailsLoader: false,
        Is2020W4Form: action.cases.Is2020W4Form === 'true' ? true : false || false,
        DisableGenerateFederalPDF:
          action.cases.DisableGenerateFederalPDF === 'true' ? true : false || false,
        DisableGenerateStatePDF:
          action.cases.DisableGenerateStatePDF === 'true' ? true : false || false,

        FederalDocBase64Code: action.cases.FederalDocBase64Code || '',
        FederalDocBefore2020Base64: action.cases.FederalDocBefore2020Base64 || '',
        StateDocBase64Code: action.cases.StateDocBase64Code || '',

        EmpId: action.cases.EmpID || '',
        payPeriodYears: action.cases.PayPeriods || [],
        PayPeriodDates: [],
        IsA2020W4Form: action.cases.IsA2020W4Form,
        w4Info: action?.cases || {},
      };
    case actionTypes.GET_W4_CONFIG_DETAILS_FAILURE:
      return {
        ...state,
        formW4Data: [],
        formStateW4Data: [],
        compensationDetails: [],
        getW4ConfigDetailsLoader: false,
        Is2020W4Form: false,
        payPeriodYears: [],
        IsA2020W4Form: '',
      };
    case actionTypes.GET_W4_CONFIG_DETAILS_LOADER:
      return {
        ...state,
        getW4ConfigDetailsLoader: true,
      };

    case actionTypes.POST_W4_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        formW4Data: action.cases.FormW4Data || [],
        formStateW4Data: action.cases.FormStateW4Data || [],
        DisableGenerateFederalPDF:
          action.cases.DisableGenerateFederalPDF === 'true' ? true : false || false,
        DisableGenerateStatePDF:
          action.cases.DisableGenerateStatePDF === 'true' ? true : false || false,
        SubmitW4ConfigDetailsLoader: false,
      };
    case actionTypes.POST_W4_CONFIG_DETAILS_FAILURE:
      return {
        ...state,
        formW4Data: [],
        formStateW4Data: [],
        SubmitW4ConfigDetailsLoader: false,
        Is2020W4Form: false,
        IsA2020W4Form: '',
      };
    case actionTypes.POST_W4_CONFIG_DETAILS_LOADER:
      return {
        ...state,
        SubmitW4ConfigDetailsLoader: true,
      };

    case actionTypes.GET_CALCULATE_TAX_DTLS_SUCCESS:
      return {
        ...state,
        payRollData: action.cases,
        getCalculateTaxLoader: false,
        getProcessPayRollLoader: false,
      };
    case actionTypes.GET_CALCULATE_TAX_DTLS_FAILURE:
      return {
        ...state,
        payRollData: action.cases,
        getCalculateTaxLoader: false,
      };

    case actionTypes.GET_CALCULATE_TAX_DTLS_LOADER:
      return {
        ...state,
        getCalculateTaxLoader: true,
      };

    case actionTypes.POST_PROCESS_PAYROLL_SUCCESS:
      return {
        ...state,
        payRollData: action.cases,
        getProcessPayRollLoader: false,
      };
    case actionTypes.POST_PROCESS_PAYROLL_FAILURE:
      return {
        ...state,
        payRollData: action.cases,
        getProcessPayRollLoader: false,
      };

    case actionTypes.POST_PROCESS_PAYROLL_LOADER:
      return {
        ...state,
        getProcessPayRollLoader: true,
      };

    case actionTypes.GET_PAY_STUBS_DATA_SUCCESS:
      return {
        ...state,
        PayPeriodDates: action.cases.PayPeriodDates || [],
        getPayStubsDataLoader: false,
      };

    case actionTypes.GET_PAY_STUBS_DATA_FAILURE:
      return {
        ...state,
        PayPeriodDates: [],
        getPayStubsDataLoader: false,
      };

    case actionTypes.GET_PAY_STUBS_DATA_LOADER:
      return {
        ...state,
        getPayStubsDataLoader: true,
      };

    case actionTypes.DOWNLOAD_PAY_STUBS_DATA_SUCCESS:
      return {
        ...state,
        downloadPayStubLoader: false,
      };

    case actionTypes.DOWNLOAD_PAY_STUBS_DATA_FAILURE:
      return {
        ...state,
        downloadPayStubLoader: false,
      };

    case actionTypes.DOWNLOAD_PAY_STUBS_DATA_LOADER:
      return {
        ...state,
        downloadPayStubLoader: true,
      };

    case actionTypes.GET_EMP_OFFCYCLE_SUCCESS:
      return {
        ...state,
        getEmpDetailsOffcycleLoader: false,
        EmployeeDetails: action.cases?.data?.MonthlyPayrollDetails || [],
        Deduct401kfromBonus:
          action.cases?.data?.Deduct401kfromBonus === 'true' ||
          action.cases?.Deduct401kfromBonus === 'true'
            ? true
            : false,
        ActiveEmployees: action.cases?.data?.ActiveEmployees,
        OffCycleCurrency: action?.cases?.CurrencyCode || '',
        SelectedLocation: action?.cases?.data?.LocationCode || '',
        Region: action?.cases?.data?.Region || '',
      };

    case actionTypes.GET_EMP_OFFCYCLE_FAILURE:
      return {
        ...state,
        getEmpDetailsOffcycleLoader: false,
        EmployeeDetails: [],
      };

    case actionTypes.GET_EMP_OFFCYCLE_LOADER:
      return {
        ...state,
        getEmpDetailsOffcycleLoader: true,
      };

    case actionTypes.PROCESS_OFFCYCLE_SUCCESS:
      return {
        ...state,
        payRollData: action.cases,
        processOffcycleLoader: false,
      };
    case actionTypes.PROCESS_OFFCYCLE_FAILURE:
      return {
        ...state,
        payRollData: action.cases,
        processOffcycleLoader: false,
      };

    case actionTypes.PROCESS_OFFCYCLE_LOADER:
      return {
        ...state,
        processOffcycleLoader: true,
      };
    case actionTypes.EMAIL_W4_DATA_LOADER:
      return {
        ...state,
        emailW4DataLoader: true,
      };
    case actionTypes.EMAIL_W4_DATA_FAILURE:
      return {
        ...state,
        emailW4DataLoader: false,
      };
    case actionTypes.EMAIL_W4_DATA_SUCCESS:
      return {
        ...state,
        emailW4DataLoader: false,
      };

    case actionTypes.GET_TAX_DOC_DETAILS_SUCCESS:
      return {
        ...state,
        getTaxDocsDetailsLoader: false,
        taxDocsDetails: action?.cases || {},
      };
    case actionTypes.GET_TAX_DOC_DETAILS_FAILURE:
      return {
        ...state,
        getTaxDocsDetailsLoader: false,
        taxDocsDetails: {},
      };
    case actionTypes.GET_TAX_DOC_DETAILS_LOADER:
      return {
        ...state,
        getTaxDocsDetailsLoader: true,
      };

    case actionTypes.SEND_TAX_DOC_MAIL_SUCCESS:
      return {
        ...state,
        sendTaxDocMailLoader: false,
      };
    case actionTypes.SEND_TAX_DOC_MAIL_FAILURE:
      return {
        ...state,
        sendTaxDocMailLoader: false,
      };
    case actionTypes.SEND_TAX_DOC_MAIL_LOADER:
      return {
        ...state,
        sendTaxDocMailLoader: true,
      };
    default:
      return state;
  }
}
